import Header from "../components/navbar";
import Hero from '../components/hero';
import Services from '../components/services';
import Pricing from '../components/pricing';
import About from '../components/about';
import Footer from '../components/Footer';

const Home = () => {

  return (
    <>
      <Header></Header>
      <Hero></Hero>
      <Services></Services>
      <Pricing></Pricing>
      <About></About>
      <Footer></Footer>
    </>

  )

}

export default Home;